<template>
   <div class="work-box">
     <bannerStatic :num="5" :title="'加入郑大智能，遇见更优秀的你'"/>
     <div class="">
        <div class="joinUs">
          <h3 class="titleStyle">等你来，一起创造精彩</h3>
          <div>
            <p class="info" v-html="jobInfoContent"></p>
          </div>
          <a-collapse class="collapse-box" :activeKey="activeKey"  accordion v-if="joblist.length>0">
            <a-collapse-panel v-for="item in joblist" :key="item.moduleId+''" :header="item.moduleName">
                <p v-for="job in item.jobOffers" :key="job.jobId+''" @click="jump(job.jobId)" >
                  <span class="name">{{job.jobName}}</span>
                  <a-icon type="right" style="float:right;font-size:12px;margin-top: 4px;"  />
                  <span class="job-info"><span>{{job.workPlace}}</span><span class="lineDevider"></span><span>{{job.salary}}</span></span>
                </p>
            </a-collapse-panel>
          </a-collapse>
           <a-collapse class="collapse-box" activeKey="1" accordion  v-if="joblist.length==0">
            <a-collapse-panel key="1" :header="`暂无招聘信息`">
            </a-collapse-panel>
          </a-collapse>
        </div>
        <div class="ourImg">
          <div class="activeBg"><p>精彩活动</p></div>
          <div class="img-box" >
            <a-row :gutter="16">
              <a-col class="box" v-for="(item,index) in jobImages" :key="item.jobImageId" :span="index<1?16:8" :class="{'spc':index<2}">
                <div class="box-in" :style="{'backgroundImage': 'url('+newsImg+item.imageUrl+ ')'}" ></div>
              </a-col>
            </a-row>
          </div>
        </div>
        <div class="contact">
          <h3 class="titleStyle">联系我们</h3>
          <div class="info-box">
            <span><img src="../../assets/img/feiji.png" alt="" srcset="">{{email}}</span>
            <span class="line">|</span>
            <span><img src="../../assets/img/dizhi.png" alt="" srcset="">{{address}}</span>
          </div>
        </div>
     </div>
   </div>
</template>
<script>
import bannerStatic from'@/components/BannerStatic/BannerStatic'
import  { newsImg } from '@/config/appSetting'
import { getJob } from '@/api/data'
export default {
  name:'work',
  components: {
    bannerStatic
  },
   data() {
      return {
        joblist:[],
        email:'',
        address:'',
        activeKey:[],
        jobInfoContent:'',
        newsImg,
        jobImages:[]
      };
    },
    methods: {
      jump(id){
        this.$router.push({path:`/WorkDetails/${id}`})
      },
  },
  mounted(){
    let _this=this
    this.email=sessionStorage.getItem("email")
    this.address=sessionStorage.getItem("address")
    getJob().then(res => {
        if (res.state) {
          if(res.data.jobOffers.length>0){
            _this.joblist=res.data.jobOffers
            _this.activeKey=[res.data.jobOffers[0].moduleId+'']
          }
          if(res.data.jobInfo.length>0){
            _this.jobInfoContent=res.data.jobInfo[0].jobInfoContent
            _this.jobImages=res.data.jobInfo[0].jobImages
          }
        }
     })
  }
}
</script>
<style lang="less" >
.work-box{
  .titleStyle{
        display: inline-block;
        margin-top: 40px;
        font-weight: 500;
        font-size: 26px;
  }
  .joinUs{
    width: 800px;
    margin:0 auto;
    overflow: hidden;
    min-height: 400px;

    .info{
       text-align: left;
       font-size: 14px;
       color: #333333;
       margin-top:20px;
       line-height: 26px;
       min-height: 100px;
    }
    .collapse-box{
      text-align: left;
      margin: 60px 0;
       .ant-collapse-arrow{
         left: 770px;
       }
    }
  }
  .ourImg{
    min-height: 600px;
    .activeBg{
      display: block;
      height: 240px;
      line-height: 240px;
      width: 100%;
      background: url(../../assets/img/join-center.jpg) no-repeat center;
      background-color: #287ee1;
      p{
        display: block;
        text-align: center;
        color:#fff;
        font-size: 40px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 2px;
      }
    }
    .img-box{
      width: 1200px;
      margin:60px auto 100px;
      .box{
        height: 297px;
        overflow: hidden;
        margin-bottom: 16px;
        .box-in{
          height: 100%;
          width: 100%;
          background-position: center;
          background-size: cover;
        }
      }
      .spc{
        height: 443px;
        overflow: hidden;
      }
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .contact{
    height: 320px;
    background: url(../../assets/img/contactus.jpg) no-repeat center;
    .info-box{
      width: 100%;
      max-width: 960px;
      height: 116px;
      background: #fff;
      margin:40px auto;
      font-size: 18px;
      padding-top:40px;
      padding:40px 10px 0;
      img{
        vertical-align: text-top;
        margin-right: 15px;
      }
      .line{
        margin: 0 20px;
        font-size: 25px;
      }
    }
  }
}
.collapse-box{
  text-align: left;
  border:0;
  border-radius: 0;
    .ant-collapse-arrow{
      left: auto !important;
      right:20px;
    }
    .ant-collapse-header{
      padding-left:20px !important;
      background:#ebebeb;
      color:#333333 !important;
      height: 52px;
      font-size:14px;
      line-height: 30px !important;
      border-radius:0 !important;
      i{
        color:#333333 !important;
      }
    }
    .ant-collapse-content-box {
      padding: 0;
      p{
        border-bottom: 1px #d9d9d9 solid;
        margin-bottom: 0;
        padding: 12px 20px 12px;
        cursor: pointer;
        .name{
          display: inline-block;
          min-width: 120px;
        }
        .job-info {
          color: #646a73;
          font-size: 12px;
          font-weight: 500;
          margin-left: 30px;
          float: right;
          min-width: 140px;
        }
        .lineDevider {
              display: inline-block;
              width: 1px;
              height: 10px;
              background-color: #bbbfc4;
              margin: 0 8px;
          }
        &:last-child{
          border-bottom: 0;
        }
      }
    }
    .ant-collapse-item-active{
      .ant-collapse-header {
          background: #4799f9;
          color:#fff  !important;
          i{
             color:#fff  !important;
          }
      } 
    }
}
</style>



